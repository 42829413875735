.tsColor1 {
  color: #409eff !important;
}
.searchbox {
  padding: 0;
}
.framePage-body .shell {
  overflow: hidden;
}
.framePage-body .shell .left {
  width: 80px;
  float: left;
  font-size: 14px;
  line-height: 30px;
  text-align: right;
  padding: 0 5px;
}
.framePage-body .shell .right {
  float: left;
  font-size: 14px;
  line-height: 30px;
  padding: 0 10px;
  cursor: pointer;
}
.framePage-body .m_list {
  width: 183px;
  height: 340px;
  float: left;
  position: relative;
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
  transition: all 0.5s;
}
.framePage-body .m_list:hover {
  transform: scale(1.03);
}
.framePage-body .m_list .m_title {
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: absolute;
  top: -24px;
  left: 0;
  z-index: 2;
}
.framePage-body .m_list .m_img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  cursor: pointer;
}
.framePage-body .m_list .m_cz {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 0 0 4px 4px;
}
.framePage-body .m_list .m_cz span {
  width: 50%;
  text-align: center;
  line-height: 26px;
  font-size: 12px;
  cursor: pointer;
}
.framePage-body .m_list .m_cz span:first-child {
  color: #409eff;
}
.framePage-body .m_list .m_cz span:last-child {
  color: #f56c6c;
}
