

























































































































































































.tsColor1 {
  color: #409eff !important;
}
.searchbox {
  padding: 0;
}
.framePage-body {
  .shell {
    overflow: hidden;
    .left {
      width: 80px;
      float: left;
      font-size: 14px;
      line-height: 30px;
      text-align: right;
      padding: 0 5px;
    }
    .right {
      float: left;
      font-size: 14px;
      line-height: 30px;
      padding: 0 10px;
      cursor: pointer;
    }
  }
  .m_list {
    width: 183px;
    height: 340px;
    float: left;
    position: relative;
    margin-right: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
    transition: all 0.5s;
    &:hover {
      transform: scale(1.03);
    }
    .m_title {
      text-align: center;
      font-size: 12px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      position: absolute;
      top: -24px;
      left: 0;
      z-index: 2;
    }
    .m_img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 4px;
      cursor: pointer;
    }

    .m_cz {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 0 0 4px 4px;
      span {
        width: 50%;
        text-align: center;
        line-height: 26px;
        font-size: 12px;
        cursor: pointer;
      }
      span:first-child {
        color: #409eff;
      }
      span:last-child {
        color: #f56c6c;
      }
    }
  }
}
